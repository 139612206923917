/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS240M
 * 화면 설명: 가입설계관리 > 전자서명진행현황
 */
<template>
  <!-- AppBar BackArrow 버튼/ 타이틀 / 검색버튼 컨테이너 --> 
  <!-- root ur-page-container -->
  <ur-page-container class="msp" title="전자서명" ref="refScrnPage" type="subpage"  :show-title="true" :action-type="actionType" :topButton="true"
    @on-scroll-bottom="fn_ScrollBottomHandler" 
    @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- Content영역 -->
    <template #frame-header-fixed>
      <div id="tooltip" class="chat-bubble type-M1 blu2" v-if="showTooltip" style="min-width:200px; width:225px; height:33px; left:auto; right:155px; top:56px">
        <div class="exp">
            필요양식발행을 볼 수 있어요
        </div>
        <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_closeTooltip"/>
      </div>
      <mo-tab-box :defaultIdx="defaultIdx" @tab-change="fn_TabChangeHandler" ref="refMoTabBox"  class="ns-move-tab-box">

        <!-- 중단건 상단 바 -->  
        <mo-tab-content :idx="1" label="중단건" >        
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title mb3">
                  <span class="sum">총<span class="count">{{countRsltIntrs}}</span>건</span>
                  <!-- 중단건 리스트 정렬 컴포넌트 -->
                  <PSListSortOptIntrs ref='refSortOptIntrs'
                                      defaultLabel='정렬선택'
                                      popupTitle="전자서명(중단건) 정렬선택"
                                      itemValue="name" 
                                      itemText="label"
                                      :sortFieldList='sortFieldListIntrs'
                                      @sortChange='fn_SortHandler'/>
                </div>
              </div>
            </mo-list-item>      
          </ur-box-container>
        </mo-tab-content>
        <!-- / 중단건 상단 바 -->  

        <!-- 완료건 상단 바 -->    
        <mo-tab-content :idx="2" label="완료건" >
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title mb3">
                  <span class="sum">총<span class="count">{{countRsltCmplt}}</span>건</span>
                   <!-- 완료건 리스트 정렬 컴포넌트 -->
                  <PSListSortOptCmplt ref='refSortOptCmplt'
                                      defaultLabel='정렬선택'
                                      popupTitle="전자서명(완료건) 정렬선택"
                                      itemValue="name" 
                                      itemText="label"
                                      :sortFieldList='sortFieldListCmplt'
                                      @sortChange='fn_SortHandler'/>
                </div>
              </div>
            </mo-list-item>      
          </ur-box-container>   
        </mo-tab-content>
        <!-- / 완료건 상단 바 -->   

      </mo-tab-box>
    </template>
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container direction="column" alignV="start" class="">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
           <!-- 중단건 --> 
          <MSPPS242D v-show="curntTabIdx==1" 
                    ref="refTabIntrs"
                    :idx="1"
                    :curntTabIdx="curntTabIdx"
                    :isTabletEvn="isTabletEvn"
                    :holyDayChkDateArry="holyDayChkDateArry"
                    @on-child-tab-mounted="fn_ChildTabMountedHandler"
                    @on-service-result="fn_ChildServiceResultHandler"                    
                    />
          <!-- 완료건 -->
          <MSPPS241D v-show="curntTabIdx==2" 
                     ref="refTabCmplt" 
                     :idx="2"
                     :curntTabIdx="curntTabIdx"
                     :holyDayChkDateArry="holyDayChkDateArry" 
                     @on-child-tab-mounted="fn_ChildTabMountedHandler"
                     @on-service-result="fn_ChildServiceResultHandler"
                     />
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- content 영역 -->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSConstants from '@/config/constants/psConstants'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import MSPPS241D from '@/ui/ps/MSPPS241D' //완료건
import MSPPS242D from '@/ui/ps/MSPPS242D' //중단건
import MSPPS201P from '@/ui/ps/MSPPS201P' //검색팝업
import MSPPI311P from '@/ui/pi/MSPPI311P' // 필요양식 발행 팝업
import PSMixinTabBoxPageScroll from '@/ui/ps/comm/PSMixinTabBoxPageScroll' // 리스트형 탭 형태 스크롤 처리
import Screen from '~systems/mixin/screen'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS240M', 
  screenId: 'MSPPS240M', 
  mixins : [PSMixinTabBoxPageScroll, Screen],

  components: {
    'PSListSortOptCmplt' :PSListSortOpt ,
    'PSListSortOptIntrs' :PSListSortOpt ,
    'MSPPS241D' : MSPPS241D,  //완료건
    'MSPPS242D' : MSPPS242D,  //중단건
    'MSPPS201P' : MSPPS201P   //검색팝업 
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {      
      PSConstants         : PSConstants, // 청약 상수      
      isTabletEvn         : false,       // 테블릿 환경 여부   
      actionType          : 'none',
      curntTabIdx         : 1 ,   // 선택한 탭 인덱스 번호
      defaultIdx          : 1 ,   // 기본 선택 탭 인덱스
      routeReqTabIdx      : '' ,  // router Param 으로 넘어온 tabIdx 값
      //isFirstCallIntrs    : false ,//중단건 최초 호출 여부 flag
      //isFirstCallCmplt    : false ,//완료건 최초 호출 여부 flag
      countRsltIntrs      : 0 , // 중단건 조회 건수 
      countRsltCmplt      : 0 , // 완료건 조회 건수     
      sortFieldListIntrs  : [], // 중단건 정렬 옵션 리스트
      sortFieldListCmplt  : [], // 완료건 정렬 옵션 리스트
      searchDataIntrs     : null , // 중단건 검색팝업 리턴값 
      searchDataCmplt     : null ,  // 완료건 검색팝업 리턴값
      holyDayChkDateArry  : [],    // 현재날짜 +10,+20일까지 날짜정보 배열   fn_ServiceCall('S') 결과에서 셋팅함
      searchPopupModalId  : null, // 검색팝업 Open  modal ID
      showTooltip         : false
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  반드시  next(fals) OR next()  호출 
  //beforeRouteEnter(to, from, next) {},//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
    this.actionType ='none' // 리셋
    //상단 검색 버튼 +  메뉴 더보기 버튼 셋팅
    this.actionType = PSCommUtil.fn_SetAppBarSearchProprty(this.fn_HeaderSerchHander, this.fn_ProprtyHandler)

    //검색 초기화 처리
    this.fn_ResetSearchData('CMPLT')
    this.fn_ResetSearchData('INTRS')

    //툴팁 출력 확인
    let tooltipMSPPI240M = localStorage.getItem('TOOLTIPSHOW_MSPPS240M')
    if (screen.width > 400 && (this.$bizUtil.isEmpty(tooltipMSPPI240M) || tooltipMSPPI240M !== 'N')) {
      this.showTooltip = true
    }

  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  async mounted () {
    //PSMixinTabBoxPageScroll override
    this.fn_SetTabBoxPageScrollMixin (this.$refs.refScrnPage,this.$refs.refMoTabBox)
    
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)  

   //RouterParam 체크
   this.routeReqTabIdx = (this.$route.params && this.$route.params.tabIdx)? this.$route.params.tabIdx : ''   
   console.log('>>>>routeReqTabIdx this.routeReqTabIdx='+this.routeReqTabIdx , this.$route.params)

   //전자서명 가능 환경
   this.isTabletEvn = await PSCommUtil.fn_IsElstEnv()
   console.log('>>>>mounted isTabletEvn this.isTabletEvn='+this.isTabletEvn)

   //전자서명이 가능하지 않는 환경이라면 완료건 탭 우선
   if(! this.isTabletEvn){
     this.defaultIdx = 2 
   } else if(this.routeReqTabIdx == '1' || this.routeReqTabIdx == '2') { //전자서명 가능한 환경에서 분기요청 파라미터 체크
    this.defaultIdx = Number(this.routeReqTabIdx)
   }
   
   this.curntTabIdx = this.defaultIdx // 선택탭 인덱스 기본 탭인덱스로 셋팅
   console.log('>>>>mounted routeReqTabIdx this.curntTabIdx='+this.curntTabIdx)
   this.$refs.refMoTabBox.setIdx(this.curntTabIdx)

    //영업일 호출 
    //호출 결과 최초 조회 처리
   this.fn_ServiceCall('S',false)
    
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {    
    window.vue.getStore('progress').dispatch('FULL') //스켈레톤 기본으로
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
  
  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
    * Function명  : fn_ServiceCall
    * 설명        : 레거시&인터페이스 서비스 콜
    ******************************************************************************/
    fn_ServiceCall (serviceName,isSearchEvent) {
      
      let pParams = {}
      
      switch (serviceName) {
        case 'S': // 현재날짜-10일까지 날짜정보
          pParams.appId = 'sli.ic'
          pParams.trnstId = ''
          pParams.fnctScCd = 'S'
          pParams.serviceId = ''
          pParams.reqSrvcNm = 'ICCommMknoCtlImpl'
          pParams.reqMthdNm = 'selListYmdInfo'
          pParams.reqVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
          pParams.resVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
          pParams.srnId = this.$options.screenId // 대표 화면 명

          //오늘날짜 기준으로 -10일 부터 조회 범위 설정
          if (! this.holyDayChkDateArry || this.holyDayChkDateArry.length === 0) {
            pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), -10)}
          } else {
            pParams.data = {slrcYmYmd: PSDateUtil.addDate(PSDateUtil.fn_CurrentDate(), -20)}
          }
          
        default:
          break
      }

      let moreData = {serviceName : serviceName, isSearchEvent : isSearchEvent}
      PSServiceManager.invoke(pParams, 
        this.fn_ServiceCallRsltHandler,
        moreData,
        (event)=>{ //fault event
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
        })

    },
  

   /******************************************************************************
    * Function명  : fn_ServiceCallRsltHandler
    * 설명        : fn_ServiceCall 호출서비스 호출결과 함수 serviceName
    ******************************************************************************/
    fn_ServiceCallRsltHandler (event, moreData) { 
 
      let rsltData = (event && event.data)? event.data : null
      let alertMesg = ''
      let trtYmd03 = 'N' // 접수일 여부 판단
      let trtYmd04 = 'N' // 출금일 여부 판단
      let findItem = null
      
      switch (moreData.serviceName) {        
        case 'S': // 현재날짜-10일까지 날짜정보

          if (!(rsltData && rsltData.icymdMngVO && rsltData.icymdMngVO.length > 0)) break

          if (! this.holyDayChkDateArry || this.holyDayChkDateArry.length === 0 ) { //연휴 값 없을시 셋팅 조회 처리
            this.holyDayChkDateArry = rsltData.icymdMngVO
            this.fn_ServiceCall('S',moreData.isSearchEvent) // 장기 연휴 처리를 위한 날짜 10일 추가 재조회
          } else {
            let arrLength = rsltData.icymdMngVO.length - 1

            for ( let i = arrLength; i >= 0 ; --i) {              
              this.holyDayChkDateArry.unshift(rsltData.icymdMngVO[i])
            }

            this.fn_TabChangeHandler(this.curntTabIdx,moreData.isSearchEvent)

          }
          break
        default:
          break
      }
    },

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
      //this.$router.go(-1)
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : Header Back 타이틀 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      // 홈으로 이동
      this.$router.push({ name: 'MSPBC002M' })      
    },

    /******************************************************************************
    * Function명  : fn_ChildTabMountedHandler
    * 설명        : 자식탭 mounted 이벤트 핸들러
    *               초기설정 처리
    ******************************************************************************/
    fn_ChildTabMountedHandler(chidTabData){
      let tabIdx = chidTabData.idx
      if(tabIdx == 1){ //중단건 탭 sort필드 리스트 셋팅
        this.sortFieldListIntrs = chidTabData.sortFieldListIntrs
      }else{ // 완료건 탭 sort필드 리스트 셋팅
        this.sortFieldListCmplt = chidTabData.sortFieldListCmplt  
      }
      
    },

    /******************************************************************************
    * Function명  : fn_ChildServiceResultHandler
    * 설명        : 자식탭 서비스 호출 결과 핸들러
    *               초기설정 처리
    ******************************************************************************/
    fn_ChildServiceResultHandler(tabIdx,rsltList,isSortReset){

      if(tabIdx == 1){
        this.countRsltIntrs = (rsltList)? rsltList.length : 0
        if(isSortReset) this.$refs.refSortOptIntrs.fn_Reset()
      } else {
        this.countRsltCmplt = (rsltList)? rsltList.length : 0
        if(isSortReset) this.$refs.refSortOptCmplt.fn_Reset()
      }

    },

    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
      let targetTab = (this.curntTabIdx == 1)? this.$refs.refTabIntrs : this.$refs.refTabCmplt
      targetTab.$emit('on-scroll-bottom')
    },

    /******************************************************************************
    * Function명  : fn_ResetSearchData
    * 설명        : 검색 데이터 초기화 처리
    *              pType == null 중단/완료건,  pType == 'INTRS' 중단건 , pType == 'CMPLT' 완료건
    ******************************************************************************/
    fn_ResetSearchData(pType){
      let optData = { searchScVal    : PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key , //검색 구분 (1:고객명,2:상품명,3:영수증번호)
                      searchKeyword  : '', //검색 키워드 
                      chnlCustId     : '' // 고객명으로 검색시 선택한 채널 고객id
                    }

      if(!pType || pType == 'INTRS') this.searchDataIntrs = Object.assign({},optData)// 중단건 검색팝업 초기값
      if(!pType || pType == 'CMPLT') this.searchDataCmplt = Object.assign({},optData)// 완료건 검색팝업 초기값
    },
 
    /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {

      let searchData       = (this.curntTabIdx == 1)? this.searchDataIntrs : this.searchDataCmplt //각 탭 마다 마지막 으로 검색한 조건 상태값 셋팅
      let searchPopupTitle = (this.curntTabIdx == 1)? '전자서명(중단건) 검색' : '전자서명(완료건) 검색'
      let targetTab = (this.curntTabIdx == 1)? this.$refs.refTabIntrs : this.$refs.refTabCmplt
      
      if(! searchData){
        searchData = {}
        searchData.searchScVal   = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key // 고객명으로 구분
        searchData.searchKeyword = '' // 키워드는 공백으로 신규조회 형
      }

      let searchEmit = (that,rtnData) =>{
        if(rtnData){
          searchData.chnlCustId     = (rtnData.chnlCustId)? rtnData.chnlCustId : ''
          searchData.searchScVal    = rtnData.searchScVal // 검색구분값
          searchData.searchKeyword  = rtnData.searchKeyword // 검색키워드    
        } else {
          searchData.searchScVal   = PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key // 고객명으로 구분
          searchData.searchKeyword = '' // 키워드는 공백으로 신규조회 형
        }
        
        that.$bottomModal.close(this.searchPopupModalId) // 모달 닫기
        
        //targetTab.$emit('on-search',searchData)
        this.fn_ServiceCall('S',true)
      }

      this.searchPopupModalId = this.$bottomModal.open(MSPPS201P, {
        properties: {
          pParntSrnId     : this.$options.screenId,   // 부모 화면 ID
          pPopupTitle     : searchPopupTitle,
          pSearchKeyword  : searchData.searchKeyword, // Default set Keyword Value
          pSearchScVal    : searchData.searchScVal    // 검색 구분 (1:고객명,2:상품명,3:영수증번호)        
        },
        listeners: {
          //대상팝업 onPopupReset $emit 이벤트명 backkey reset or reset
          onPopupReset : () => {
            searchEmit(this,null)
          },
          // 대상팝업 onPopupSearch $emit 이벤트명 
          onPopupSearch: (rtnData) => {
            searchEmit(this,rtnData)
          }          
        }
      })

    },
    /******************************************************************************
    * Function명  : fn_ProprtyHandler
    * 설명        : Header 메뉴 필요양식발행 팝업 열기
    ******************************************************************************/
    fn_ProprtyHandler(){
      let lv_Vm = this
      lv_Vm.pi311p = lv_Vm.$bottomModal.open(MSPPI311P, {
        properties: {
          pParntSrnId: 'MSPPS240M'
        },
        listeners: {
          // 팝업 닫기
          closePopup: () => {
            lv_Vm.$bottomModal.close(lv_Vm.pi311p)
          }
        }
      })
    },

    /******************************************************************************
    * Function명  : fn_MoreMenuHandler
    * 설명        : Header 메뉴 더보기
    ******************************************************************************/
    fn_MoreMenuHandler(){
      //팝업 열기 
      let subMenuModalId = this.$bottomModal.open(PSHeaderSubMenuPopup, {
        properties: {          
          parentSrnId  : this.$options.screenId, // 부모 화면 ID
          popupTitle   : '청약설계 업무 더보기',                    
          subMenuList  : PSCommUtil.fn_GetOfrPlanMoreMenuList(), //청약설계 보조메뉴 리스트
          closeBtn     : true 
        },
        listeners: {
          popupEventClose : () => {
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
          },
          popupEventRouterMove: (rtnData) => {//subMenuList에서 선택함 Item 리턴
            this.$bottomModal.close(subMenuModalId) // 모달 닫기            
          },      
        }
      },{properties: {closeBtn: true}})
    },
    
    /******************************************************************************
    * Function명  : fn_TabChangeHandler
    * 설명        : tab box Change Handler                     
    ******************************************************************************/
    fn_TabChangeHandler(idx,isSearchEvent){
      this.curntTabIdx = idx
      
      let targetTab = null
      let srchOptData = null

      isSearchEvent = (isSearchEvent)? isSearchEvent : false 

      
      if(this.curntTabIdx == 2){ // 완료건        
        //검색 초기화 처리
        this.fn_ResetSearchData('CMPLT')        
        targetTab = this.$refs.refTabCmplt

      } else if(this.curntTabIdx == 1 ){ //중단건       
        //검색 초기화 처리
         this.fn_ResetSearchData('INTRS')        
         targetTab = this.$refs.refTabIntrs
      }

      srchOptData = (this.curntTabIdx == 1)? this.searchDataIntrs : this.searchDataCmplt

      if(targetTab) targetTab.$emit('on-search',srchOptData)
    },


    /******************************************************************************
    * Function명  : fn_SortHandler
    * 설명        : Header 메뉴 더보기
    ******************************************************************************/
    fn_SortHandler (pSortOrder){
      let targetTab = (this.curntTabIdx == 1)? this.$refs.refTabIntrs : this.$refs.refTabCmplt
      targetTab.$emit('on-sort-change',pSortOrder)
    },

    /******************************************************************************
    * Function명  : fn_closeTooltip
    * 설명        : 툴팁 닫기시
    ******************************************************************************/
    fn_closeTooltip (pSortOrder){
      this.showTooltip = false
      localStorage.setItem('TOOLTIPSHOW_MSPPS240M', 'N')
    },

  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/


  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>
<style scoped>
  #tooltip:after {
    width: 0; height: 0;  
    /* border-top: #3190FF 30px solid; */
    border-bottom: #67BCE0 20px solid;
    border-left: transparent 10px solid;
    border-right: transparent 10px solid;
    right: -10px; top: -7px;
    vertical-align: center;
    transform:rotate(45deg);
  }
</style>